const appConfig = {
  apiPrefix: "https://api.askred.ai",
  // apiPrefix: "http://123.100.226.65:3000",
  authenticatedEntryPath: "/home",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "",
  enableMock: false,
};

export default appConfig;
