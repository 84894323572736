import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { isFunction } from 'lodash'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {
	const roleMatched = useMemo(() => {
		const mappedAuthority = authority.map(auth => {
			if (isFunction(auth)) {
				return auth({
					userRole: userAuthority
				});
			}
			return auth;
		});

		if (mappedAuthority.includes(false)) {
			return false;
		}

		return mappedAuthority.some(role => userAuthority.includes(role))
	}, [authority, userAuthority])
    
    if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
		return !emptyCheck
	}

    return roleMatched
}

export default useAuthority