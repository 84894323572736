import { Loading } from 'components/shared'
import {
	LAYOUT_TYPE_BLANK,
	LAYOUT_TYPE_CLASSIC,
	LAYOUT_TYPE_DECKED,
	LAYOUT_TYPE_MODERN,
	LAYOUT_TYPE_SIMPLE,
	LAYOUT_TYPE_STACKED_SIDE
} from 'constants/theme.constant'
import { Suspense, lazy, memo, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import useTimer from 'utils/hooks/useTimer'
import { fetchCentralBranch, fetchRegionalBranch, fetchInsuranceCompanyList } from 'utils/common'

const layouts = {
	[LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
	[LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
	[LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
	[LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
	[LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const TIME_INTERVAL = 60 * 20 * 1000 // 20 minutes;

const Layout = () => {
	const dispatch = useDispatch()

	const commonSelector = useSelector((state) => state.base.common);
	const layoutType = useSelector((state) => state.theme.layout.type)

	const { authenticated } = useAuth()
	const projectId = get(commonSelector, "projectId", "");

	useDirection()

	useLocale()

	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}

		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])


	// const timer = useTimer(TIME_INTERVAL);
	
	// if (timer === TIME_INTERVAL) {
	// 	fetchCentralBranch({
    //         dispatch,
    //         projectId,
    //       });

    //     fetchRegionalBranch({
    //         dispatch,
    //         projectId,
    //     });

    //     fetchInsuranceCompanyList({
    //         dispatch,
    //         projectId,
    //     });
	// }

	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />

		</Suspense>
	)
}

export default memo(Layout)