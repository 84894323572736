import React, { useState, useRef, useEffect } from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { MdOutlineFileCopy, MdDeleteOutline } from "react-icons/md";
import { Button, InputGroup, Input, Notification, toast } from "components/ui";

const InputGroupFile = (props) => {
  const {
    label,
    currentFiles = [],
    onChange = noop,
    onDelete = noop,
    disabled,
    ...rest
  } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(currentFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFiles.length]);

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    if (disabled) return;
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target && event.target.files.length === 0) return;

    const fileUploaded = event.target.files[0];
    const maxFileSize = 1000 * 1000 * 2; // 2MB

    if (fileUploaded.size > maxFileSize) {
      return toast.push(
        <Notification title="Error" type="danger" duration={2500}>
          Ukuran file tidak boleh lebih dari 2MB!
        </Notification>,
        {
          placement: "top-end",
        }
      );
    }

    const newFiles = [...files, fileUploaded];
    setFiles(newFiles);
    onChange(newFiles);
  };

  return (
    <div className="single-upload">
      <label className="block text-sm font-normal text-[#525252] mb-1">
        {label}
      </label>
      {files.length > 0 && (
        <div className="border border-[#d4d4d4] px-3 py-2 rounded-[8px] mb-2">
          {files.map((file, index) => (
            <div
              className={`flex flex-row justify-between ${
                files.length - 1 !== index ? "mb-3" : ""
              }`}
              key={`key-${index}`}
            >
              <div className="col-span-2 flex flex-row items-center gap-2 truncate">
                <MdOutlineFileCopy size={18} />
                <p className="flex-1 text-xs font-normal text-[#525252] truncate">
                  {file.name}
                </p>
              </div>
              <div className="col-span-1 flex flex-row gap-2 justify-end">
                {/* <button className="bg-[#e0edfd] text-[#4E84C3] text-sm px-4 py-2 rounded-[6px]">
                  Lihat Dokumen
                </button> */}
                {!disabled && (
                  <button
                    className="bg-[#fcdada] px-3 py-1 rounded-[6px]"
                    onClick={() => {
                      if (disabled) return;
                      const updatedFiles = files.filter((_, i) => i !== index);
                      const deletedFile = files.find((_, i) => i === index);

                      onDelete(deletedFile, updatedFiles);
                      setFiles(updatedFiles);
                    }}
                  >
                    <MdDeleteOutline color="#bf3636" size={18} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <InputGroup className="mb-4">
        <Button
          className="bg-[#e5e5e5] border border-[#a3a3a3] text-[#646464] text-sm font-normal"
          onClick={handleClick}
        >
          Pilih File
        </Button>
        <Input
          type="text"
          className={`cursor-pointer border border-[#a3a3a3] !rounded-r-[8px] ${
            disabled ? "bg-[#f3f4f6]" : ""
          }`}
          placeholder="Pilih dokumen file"
          onClick={handleClick}
          readOnly
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          className="hidden"
          disabled={disabled}
          {...rest}
        />
      </InputGroup>
    </div>
  );
};

InputGroupFile.propTypes = {
  currentFiles: PropTypes.array,
  onDelete: PropTypes.func,
};

export default InputGroupFile;
