
import { createServer } from 'miragejs'
import appConfig from 'configs/app.config'

import { signInUserData } from './data/authData'
import { accountFormData, formStatus, dataProfesi, databaseProfesi, dataKetentuanMedis, dataTarifPremi, dataPersyaratanKlaim, dataTemplate, dataPKS, dataBiaya, dataPenerbitanPolis } from './data/konfigurasiData'

import { authFakeApi, konfigurasiFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer({ environment = 'test' }) {
    return createServer({
        environment,
        seeds(server) {
            server.db.loadData({
                signInUserData,
                accountFormData,
                formStatus,
                dataProfesi,
                databaseProfesi,
                dataKetentuanMedis,
                dataTarifPremi,
                dataPersyaratanKlaim,
                dataTemplate,
                dataPKS,
                dataBiaya,
                dataPenerbitanPolis
            })
        },
        routes() {
            this.urlPrefix = ''
            this.namespace = ''
            this.passthrough(request => {
                let isExternal = request.url.startsWith('http')
                return isExternal
            })
            this.passthrough()


            authFakeApi(this, apiPrefix)
            konfigurasiFakeApi(this, apiPrefix)
        },
    })
}