import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import useAuthority from 'utils/hooks/useAuthority'

const AuthorityCheck = props => {
	const { userAuthority = [], authority = [], isHide = noop, children } = props

	let roleMatched = useAuthority(userAuthority, authority)

	const isHidden = isHide({ userRole: userAuthority })

	if (isHidden) {
		roleMatched = false
	}

	return roleMatched ? children : <></>
}

AuthorityCheck.propTypes = {
	userAuthority: PropTypes.array,
	authority: PropTypes.array,
}

export default AuthorityCheck