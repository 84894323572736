import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import creditNote from './creditNote'
import debitNote from './debitNote'
import auth from './auth'
import base from './base'
import dataPolis from "./dataPolis"

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        creditNote,
        debitNote,
        dataPolis,
        ...asyncReducers,
    })

    if (action.type === 'RESET_APP') {
        return combinedReducer(undefined, { type: undefined });
    }

    return combinedReducer(state, action)
}
  
export default rootReducer
