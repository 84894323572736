import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  currentRouteKey: "",
  projectId: "",
  centralBankList: [],
  regionalBankList: [],
  insuranceCompanyList: [],
};

export const commonSlice = createSlice({
  name: "base/common",
  initialState,
  reducers: {
    setCurrentRouteKey: (state, action) => {
      state.currentRouteKey = action.payload;
    },
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setCentralBankList: (state, action) => {
      state.centralBankList = action.payload;
    },
    setRegionalBankList: (state, action) => {
      state.regionalBankList = action.payload;
    },
    setInsuranceCompanyList: (state, action) => {
      state.insuranceCompanyList = action.payload;
    },
  },
});

export const {
  setCurrentRouteKey,
  setProjectId,
  setCentralBankList,
  setRegionalBankList,
  setInsuranceCompanyList,
} = commonSlice.actions;

export default commonSlice.reducer;
