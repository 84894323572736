import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "creditNote/data",
  initialState: {
    loading: true,
    creditNoteDetail: {},
    creditNoteInsured: {},
  },
  reducers: {
    updateCreditNoteDetail: (state, action) => {
      state.creditNoteDetail = action.payload;
    },
    updateCreditNoteInsured: (state, action) => {
      state.creditNoteInsured = action.payload;
    },
  },
});

export const { updateCreditNoteDetail, updateCreditNoteInsured } =
  dataSlice.actions;

export default dataSlice.reducer;
