import axios from "axios";
import get from "lodash/get";
import appConfig from "configs/app.config";
import { CONTENT_TYPE, REQUEST_HEADER_AUTH_KEY } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import { initialState, setUser } from "store/auth/userSlice";
import deepParseJson from "utils/deepParseJson";
import store from "../store";
import { onSignOutSuccess, setToken } from "../store/auth/sessionSlice";
import { apiRefreshToken } from "./AuthService";
const unauthorizedCode = [401];
const forbidenCode = [403];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix
});

BaseService.interceptors.request.use(
  (config) => {

    const persistState = store.getState();
    const accessToken = get(persistState, 'auth.session.token', '');

    // const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    // const persistData = deepParseJson(rawPersistData);
    // const accessToken = persistData.auth.session.token;

    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = `${accessToken}`
      config.headers[CONTENT_TYPE] = 'application/json'
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { response } = error;
    const originalRequest = error.config

    if (response) {
      if (response && unauthorizedCode.includes(response.status)) {
        store.dispatch(onSignOutSuccess());
        store.dispatch(setUser(initialState))
      }

      if (response.data.message === 'email is not valid !' && forbidenCode.includes(response.status)) {
        store.dispatch(onSignOutSuccess());
        store.dispatch(setUser(initialState))
      }

      if (response.data.message === 'jwt malformed' && forbidenCode.includes(response.status)) {
        store.dispatch(onSignOutSuccess());
        store.dispatch(setUser(initialState))
      }

      if (response.data.message === 'jwt expired' && forbidenCode.includes(response.status)) {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
        const persistData = deepParseJson(rawPersistData);

        const refreshToken = persistData.auth.user.refresh_token;
        const email = persistData.auth.user.email;


        try {
          const result = await apiRefreshToken({ email: email, refresh_token: refreshToken })

          store.dispatch(setToken(result.data.data.access_token))
          originalRequest.headers.Authorization = result.data.data.access_token

          return axios(originalRequest)
        } catch (error) {
          store.dispatch(onSignOutSuccess());
        }
      }

      return Promise.reject(error);
    }

  }
);

export default BaseService;
