import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    refresh_token: '',
    avatar: '',
    userName: '',
    email: '',
    isComplete: false,
    authority: []
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        setAvatar: (state, action) => {
            state.avatar = action.payload
        },
        setIsComplete: (state, action) => {
            state.isComplete = action.payload
        },
        setIdPusat: (state, action) => {
            state.pusat_id = action.payload
        }
    },
})

export const { setUser, setAvatar, setIsComplete, setIdPusat } = userSlice.actions

export default userSlice.reducer