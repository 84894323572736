import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "debitNote/data",
  initialState: {
    loading: true,
    debitNoteDetail: {},
    debitNoteInsuredDetail: {},
  },
  reducers: {
    updateDebitNoteDetail: (state, action) => {
      state.debitNoteDetail = action.payload;
    },
    updateDebitNoteInsuredDetail: (state, action) => {
      state.debitNoteInsuredDetail = action.payload;
    },
  },
});

export const { updateDebitNoteDetail, updateDebitNoteInsuredDetail } =
  dataSlice.actions;

export default dataSlice.reducer;
