import { get } from "lodash";
import {
  getListCentralBranch,
  getListRegionalBranch,
  getInsuranceByProjectId,
} from "services/ClaimService";
import {
  setCentralBankList,
  setRegionalBankList,
  setInsuranceCompanyList,
} from "store/base/commonSlice";

export const fetchCentralBranch = async ({ dispatch, projectId }) => {
  const response = await getListCentralBranch({
    project_id: projectId,
  });
  const result = get(response, "data.data", {});

  dispatch(setCentralBankList(result));

  return result;
};

export const fetchRegionalBranch = async ({ dispatch, projectId }) => {
  const response = await getListRegionalBranch({
    project_id: projectId,
  });
  const result = get(response, "data.data", []);

  dispatch(setRegionalBankList(result));

  return result;
};

export const fetchInsuranceCompanyList = async ({ dispatch, projectId }) => {
  const response = await getInsuranceByProjectId({
    project_id: projectId,
  });

  const result = get(response, "data.data", []);

  dispatch(setInsuranceCompanyList(result));

  return result;
};
