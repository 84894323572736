import ApiService from "./ApiService";

export async function getChatList(data) {
  return ApiService.fetchData({
    url: "users/chat/list",
    method: "POST",
    data,
  });
}

export async function storeChat(data) {
  return ApiService.fetchData({
    url: "users/chat/store",
    method: "POST",
    data,
  });
}

export async function deleteChat(id) {
  return ApiService.fetchData({
    url: `users/chat/delete/${id}`,
    method: "DELETE",
  });
}

export async function getChatFile(data) {
  return ApiService.fetchData({
    url: "users/chat/file",
    method: "GET",
    data,
  });
}
